<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant
            type="search"
            name="plantCd"
            v-model="searchParam.plantCd"
          ></c-plant>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            type="year"
            default="today"
            label="년도"
            name="inspectionYear"
            v-model="searchParam.inspectionYear"
          />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            type="search"
            stepperGrpCd="REST_FACILITIES_HYGIENE_INSPECTION_STEP_CD"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="stepCd"
            label="LBLSTEPPROGRESS"
            v-model="searchParam.stepCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="휴게시설 위생점검표 목록"
      :columns="grid.columns"
      :data="grid.data"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="openPop" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hygieneInspection',
  data() {
    return {
      searchParam: {
        plantCd: null,
        inspectionYear: '',
        stepCd: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
            style: 'width: 100px',
          },
          {
            name: 'hygieneInspectionName',
            field: 'hygieneInspectionName',
            label: '위생점검명',
            align: 'left',
            sortable: true,
            style: 'width: 250px',
            type: 'link'
          },
          {
            name: 'locationRestFacilitiesName',
            field: 'locationRestFacilitiesName',
            label: '휴게시설',
            align: 'left',
            sortable: true,
            style: 'width: 180px',
          },
          {
            name: 'inspectionYear',
            field: 'inspectionYear',
            label: '년도',
            style: 'width:100px',
            align: "center",
            sortable: true,
          },
          {
            name: 'checkerName',
            field: 'checkerName',
            label: '점검자',
            style: 'width:130px',
            align: "center",
            sortable: true,
          },
          // {
          //   name: 'stepName',
          //   field: 'stepName',
          //   label: '진행단계',
          //   style: 'width:100px',
          //   align: "center",
          //   sortable: true,
          // },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        width: '70%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.rtf.his.hygieneInspection.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '휴게시설 위생점검표';
      this.popupOptions.param = {
        sopRestFacilitiesHygieneInspectionId: row ? row.sopRestFacilitiesHygieneInspectionId : '',
      };
      this.popupOptions.target = () => import(`${'./hygieneInspectionDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
